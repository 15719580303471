import { Tag } from "@patientmpower/spiro";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AxisDomain } from "recharts/types/util/types";

import {
  IPatientBaseline,
  IPatientBaselineData,
} from "../../../../../../@types/Baselines";
import {
  MeasurementTypes,
  SelectMeasurementPayloadType,
} from "../../../../../../@types/Measurements";
import { Notification } from "../../../../../../@types/Notification";
import { IUserPreferences } from "../../../../../../@types/Preferences";
import { ChartConfig } from "../../../../../../components/ChartCard/ChartCard.types";
import { ModalChart } from "../../../../../../components/ModalChart";
import { ToastNotification } from "../../../../../../components/ToastNotification";
import { AUTH_UNIT_PREFERENCES } from "../../../../../../constants/localStorageKeys";
import { spirometryMeasurementsValues } from "../../../../../../constants/measurements";
import { useMultipleMeasurements } from "../../../../../../hooks/queries/measurements";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { useUserPreferences } from "../../../../../../hooks/useUserPreferences";
import { userService } from "../../../../../../services/userService";
import { getSourceLegends } from "../../../../../../utils/chartSourceUtils";
import { getShortFormattedDate } from "../../../../../../utils/dateFormatter";
import {
  MeasurementContainer,
  HeaderContainer,
  Title,
  Subtitle,
} from "../../MeasurementModal.styles";
import { MeasurementModalProps } from "../../MeasurementModal.types";
import { CustomOptionsBar, DateFilterOptionType } from "../CustomOptionsBar";
import { dateFilterOptions } from "../CustomOptionsBar/dateFilterOptions";
import { ModalMeasurementsTable } from "../ModalMeasurementsTable/ModalMeasurementsTable";
import { ModalSkeleton } from "../ModalSkeleton";
import { ModalOptions } from "../OptionsPopover/Options.types";
import { SubtitleConatiner } from "./MeasurementSection.styles";

type MeasurementSectionProps = Omit<
  MeasurementModalProps,
  "spirometryMeasurements"
> & {
  onSelectChartValue?: (selectedChartValue: any) => void;
  onSelectedTableView: (showMeasurementsTable: boolean) => void;
  isPinnedGraph?: boolean;
  checkNewData?: (mostRecentDate: dayjs.Dayjs) => void;
};

export function MeasurementSection({
  patientId,
  cardTitle,
  measurementType,
  minValue,
  maxValue,
  onSelectChartValue,
  onSelectedTableView,
  isSpirometry,
  isPinnedGraph,
  unit,
  checkNewData,
}: MeasurementSectionProps) {
  const getGraphPreferences = (): string => {
    const preferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences) as IUserPreferences;

      if (
        isSpirometry &&
        parsedPreferences.dataDisplayPreference !== undefined
      ) {
        return parsedPreferences.dataDisplayPreference;
      }
    }

    return "";
  };

  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "50%",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(getGraphPreferences);
  const {
    preferences,
    changeUserPreferences,
    portalPreferences,
    changePortalPreferences,
  } = useUserPreferences();
  const [spirometryTestIdValue, setSpirometryTestIdValue] = useState<
    string | null | undefined
  >();

  const { widthView } = useIsMobile();

  const currentDate = dayjs();
  let initialFilterDate = {
    endDate: currentDate,
    label: dateFilterOptions[4].label,
    startDate: dateFilterOptions[4].value(currentDate),
  };

  const date = searchParams.get("date");
  if (dayjs(date).isValid()) {
    initialFilterDate = {
      startDate: dayjs(date),
      endDate: dayjs(date),
      label: "",
    };
  }

  const [selectedFilterDate, setSelectedFilterDate] =
    useState<DateFilterOptionType>(initialFilterDate);

  const [showMeasurementsTable, setShowMeasurementsTable] =
    useState<boolean>(false);

  const { hideFvcRejected, hideFev1Rejected, hideFvcUsable, hideFev1Usable } =
    preferences;

  const chartConfigs: ChartConfig[] = [];
  const allMeasurements: Map<string, any[]> = new Map<string, any[]>();
  const allPatientBaselines: Map<string, IPatientBaselineData> = new Map<
    string,
    IPatientBaselineData
  >();

  let mainValues: any = { min: 0, max: 0, average: 0 };

  const measurementTypes: MeasurementTypes[] = [measurementType];
  if (measurementType === "bp_systolic") {
    measurementTypes.push("bp_diastolic");
  }

  const { data, refetch, isRefetching, isFetching } = useMultipleMeasurements({
    patientId,
    zoomedVersion: true,
    types: measurementTypes,
    toDate: selectedFilterDate.endDate.format(),
    fromDate: selectedFilterDate.startDate.format(),
    filter,
    hideFvcRejected,
    hideFev1Rejected,
    hideFvcUsable,
    hideFev1Usable,
  });

  if (data && data.length > 0 && data[0]) {
    const { mainMeasurementValues } = data[0] as any;
    mainValues = mainMeasurementValues;

    if (spirometryTestIdValue !== mainValues.lastSpirometryTestId) {
      setSpirometryTestIdValue(mainValues.lastSpirometryTestId);
    }
    data.forEach((d: any) => {
      if (!d) return;

      const { type, measurements, patientBaselines } = d;

      const activeBaseline = (patientBaselines as IPatientBaseline[])?.find(
        (x: IPatientBaseline) => x.archived === false
      )?.baseline;

      allPatientBaselines.set(type, {
        activeBaseline,
        baselines: patientBaselines,
      });

      if (d.mainMeasurementValues.maxDate && checkNewData) {
        checkNewData(d.mainMeasurementValues.maxDate);
      }

      if (measurements && measurements.size > 0) {
        measurements.forEach((measurementsMap: any, key: string) => {
          const source = (key || "unknown").toLowerCase();
          const sourceLegend = getSourceLegends(type, source);
          const { color, shape, lineOpacity, isDotClickable } = sourceLegend;

          if (measurementType === "bp_systolic" && source === "apple-health") {
            return;
          }

          allMeasurements.set(`${type}|${source}`, measurementsMap);
          chartConfigs.push({
            dot: false,
            dotShape: shape,
            lineType: "monotone",
            yKey: type,
            color,
            lineOpacity,
            isDotClickable,
            selectedSpirometryTestId: spirometryTestIdValue
              ? parseInt(spirometryTestIdValue, 10)
              : undefined,
            min: minValue,
            max: maxValue,
            source,
          });
        });
      }
    });
  }

  useEffect(() => {
    if (showMeasurementsTable) setSpirometryTestIdValue(null);
  }, [showMeasurementsTable]);

  useEffect(() => {
    const spirometryTestId = searchParams.get("spirometryTestId");
    setSpirometryTestIdValue(spirometryTestId);

    return () => {
      const spirometryTestId = searchParams.get("spirometryTestId");

      if (spirometryTestId == null && isPinnedGraph) return;

      searchParams.delete("spirometryTestId");
      setSearchParams(searchParams);

      setSelectedFilterDate(initialFilterDate);
    };
  }, []);

  const getMeasurementTimeBySpirometryTestId = (
    spirometryTestIdValue: string
  ) => {
    let date = "";
    allMeasurements.forEach((measurement: any) => {
      measurement.forEach((entry: SelectMeasurementPayloadType) => {
        if (
          entry.spirometryTestIds &&
          entry.spirometryTestIds.find(
            (id) => id === parseInt(spirometryTestIdValue, 10)
          )
        ) {
          date = entry.time;
        }
      });
    });
    return date;
  };

  useEffect(() => {
    const setSelectChartValue = (value: any) =>
      onSelectChartValue && onSelectChartValue(value);
    if (spirometryTestIdValue) {
      setSelectChartValue({
        spirometryTestIds: [parseInt(spirometryTestIdValue, 10)],
        time: getMeasurementTimeBySpirometryTestId(spirometryTestIdValue),
      } as SelectMeasurementPayloadType);
    } else {
      setSelectChartValue(null);
    }
  }, [spirometryTestIdValue]);

  useEffect(() => {
    setSpirometryTestIdValue(null);

    if (!isRefetching) {
      const setSelectChartValue = (value: any) =>
        onSelectChartValue && onSelectChartValue(value);

      setSelectChartValue(null);
      refetch();
    }
  }, [filter, selectedFilterDate, preferences, portalPreferences.scaleYAxis]);

  const domainByGlobalPreferences = useMemo(() => {
    if (portalPreferences.scaleYAxis) {
      return [
        (dataMin: number) => Math.floor(dataMin),
        (dataMax: number) => Math.ceil(dataMax),
      ] as AxisDomain;
    }

    if (chartConfigs.length > 0 && chartConfigs[0].min && chartConfigs[0].max) {
      const max = chartConfigs[0].max ?? 0;
      const min = chartConfigs[0].min ?? 0;
      return [
        () => Math.floor(min),
        (dataMax: number) =>
          dataMax > max ? Math.ceil(dataMax) : Math.ceil(max),
      ] as AxisDomain;
    }

    return [0, (dataMax: number) => Math.ceil(dataMax)] as AxisDomain;
  }, [portalPreferences.scaleYAxis, chartConfigs]);

  const measurementChartWidth = useMemo(() => {
    if (isPinnedGraph) {
      return "100%";
    }

    if (widthView > 1530) {
      return 834;
    }
    if (widthView < 1250) {
      return 550;
    }
    return 664;
  }, [widthView]);

  const handleOnChangeModalOptions = (options: ModalOptions) => {
    changeUserPreferences(options);
  };

  const handledDisplayChange = (filter: string) => {
    setFilter(filter);

    const preferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences) as IUserPreferences;
      parsedPreferences.dataDisplayPreference = filter;

      localStorage.setItem(
        AUTH_UNIT_PREFERENCES,
        JSON.stringify(parsedPreferences)
      );
    }
  };

  const handleOnChangeScaleYAxis = (scaleYAxis: boolean) => {
    const newPortalPreferences = { ...portalPreferences, scaleYAxis };
    changePortalPreferences(newPortalPreferences);
    userService.updateScaleYAxis(scaleYAxis);
  };

  const handleOnBaselineChange = (notification: Notification) => {
    setNotification(notification);

    if (notification.type === "success") {
      refetch();
    }

    setTimeout(() => {
      setNotification({
        show: false,
        message: "",
        type: "error",
        width: undefined,
      });
    }, 3500);
  };

  const customChartConfig = chartConfigs.map((currentChartConfig) => ({
    ...currentChartConfig,
    dot: spirometryMeasurementsValues.indexOf(measurementType) !== -1,
  }));

  const formattedDateRange = `${getShortFormattedDate(
    selectedFilterDate.startDate.format()
  )} - ${getShortFormattedDate(selectedFilterDate.endDate.format())}`;

  const highestOfDayLabel = filter ? " (Highest of the day) " : "";

  const acceptabilityHideUsableLabel =
    (hideFev1Usable && measurementType === "fev1") ||
    (hideFvcUsable && measurementType === "fvc")
      ? " (Usable hidden) "
      : "";

  const acceptabilityHideRejectedLabel =
    (hideFev1Rejected && measurementType === "fev1") ||
    (hideFvcRejected && measurementType === "fvc")
      ? " (Rejected hidden) "
      : "";

  const subTitle = `${formattedDateRange}${highestOfDayLabel}${acceptabilityHideUsableLabel}${acceptabilityHideRejectedLabel}`;

  const getNoDataMessage = () => {
    if (
      (measurementType === "fev1" && (hideFev1Rejected || hideFev1Usable)) ||
      (measurementType === "fvc" && (hideFvcRejected || hideFvcUsable)) ||
      (measurementType === "pef" &&
        (hideFvcRejected ||
          hideFvcUsable ||
          hideFev1Rejected ||
          hideFev1Usable))
    ) {
      return (
        <Tag variant="tertiary">
          No data available. Try removing filters or selecting a different date
          range.
        </Tag>
      );
    }

    return <Tag variant="tertiary">No data available for this date range.</Tag>;
  };

  return isFetching || isRefetching ? (
    <ModalSkeleton isPinnedGraph={isPinnedGraph} />
  ) : (
    <MeasurementContainer>
      <HeaderContainer
        style={
          showMeasurementsTable
            ? { marginBottom: "0px" }
            : { marginBottom: "20px" }
        }
      >
        <Title>{cardTitle}</Title>
        <Subtitle>{subTitle}</Subtitle>

        <SubtitleConatiner>
          <CustomOptionsBar
            onChange={(selectedDateFilter) => {
              const { label } = selectedDateFilter;

              if (label === "custom" || label !== selectedFilterDate.label) {
                setSelectedFilterDate(selectedDateFilter);
              }
            }}
            onTableViewChange={(enableTableView) => {
              setShowMeasurementsTable(enableTableView);
              onSelectedTableView(enableTableView);
            }}
            selectedFilterDate={selectedFilterDate}
            cardTitle={cardTitle}
            initialScaleYAxis={portalPreferences.scaleYAxis ?? false}
            initialOptions={preferences}
            initialDisplay={getGraphPreferences()}
            onChangeOption={handleOnChangeModalOptions}
            onChangeScaleYAxis={handleOnChangeScaleYAxis}
            onDisplayChange={handledDisplayChange}
            onBaselineChange={handleOnBaselineChange}
            measurementType={measurementType}
            patientId={patientId}
            baseline={allPatientBaselines.get(measurementType)?.activeBaseline}
            unit={unit}
          />
          {allMeasurements.size === 0 ? (
            <div style={{ marginLeft: "10px" }}>{getNoDataMessage()}</div>
          ) : null}
        </SubtitleConatiner>
      </HeaderContainer>

      {!showMeasurementsTable ? (
        <ModalChart
          onRowClick={
            onSelectChartValue &&
            ((selectedMeasurementData) => {
              onSelectChartValue(selectedMeasurementData);
            })
          }
          height="78%"
          data={allMeasurements || new Map<string, any[]>()}
          mainValues={mainValues}
          width={measurementChartWidth}
          chartConfig={customChartConfig}
          yConfig={{ domain: domainByGlobalPreferences }}
          dateInterval={selectedFilterDate}
          isSpirometry={isSpirometry}
          isPinnedGraph={isPinnedGraph}
          isBloodPressure={measurementType !== "bp_systolic"}
          patientBaselines={
            allPatientBaselines.get(measurementType)?.baselines ?? []
          }
          minValue={minValue}
          maxValue={maxValue}
        />
      ) : (
        <ModalMeasurementsTable
          data={allMeasurements}
          measurementType={measurementType}
          isPinnedGraph={isPinnedGraph}
        />
      )}

      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </MeasurementContainer>
  );
}
